/* eslint-disable no-console */
//Apolo client
import { useState } from 'react';

import { Stack, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from 'react-query';
import { notify } from 'src/pages/Calculator/utils/handleNotify';
import { getFundsHistory } from 'src/services/operation';

const PERIOD_OPTIONS = [
  {
    label: '12 Meses',
    value: 12,
  },
  {
    label: '24 Meses',
    value: 24,
  },
  {
    label: 'Tudo',
    value: null,
  },
];

export default function OperationFIDCLiabilityRentabilityHistory({
  cnpj,
  name,
}) {
  const theme = useTheme();

  const [chartSeries, setChartSeries] = useState([]);
  const [chartXAxis, setChartXAxis] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(12);

  const { isLoading } = useQuery(
    ['fund-histories', cnpj, selectedPeriod],
    () =>
      getFundsHistory({
        CNPJs: [cnpj],
        period: selectedPeriod,
      }),
    {
      onSuccess: (histories) => {
        console.log({ histories });

        const allDates = histories.flatMap(({ data_competencia }) =>
          format(new Date(data_competencia), 'MM/yyyy', { locale: ptBR }),
        );
        const rentabilityHistory = histories.flatMap(
          ({ rentabilidade_mensal }) => rentabilidade_mensal,
        );

        setChartSeries([
          {
            name,
            data: rentabilityHistory,
            color: theme.palette.primary.main,
          },
        ]);
        setChartXAxis(allDates);
      },
      onError: () => notify('Erro ao buscar histórico do fundo A', 'error'),
    },
  );

  const handleClickPeriod = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <div>
      <Typography mt={4} variant="h6">
        -Histórico de rentabilidade
      </Typography>
      <Stack my={2} flexDirection="row" justifyContent="flex-end" gap={1}>
        {!isLoading &&
          PERIOD_OPTIONS.map((option) => (
            <Typography
              key={option.value}
              sx={{
                cursor: 'pointer',
                textDecoration:
                  option.value === selectedPeriod ? 'underline' : 'none',
              }}
              variant="caption"
              onClick={() => handleClickPeriod(option.value)}>
              {option.label}
            </Typography>
          ))}
      </Stack>
      <ReactApexChart
        series={chartSeries}
        options={{
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            text: '',
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: chartXAxis,
          },
        }}
      />
    </div>
  );
}
