import { useEffect, useState } from 'react';

import {
  Divider,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { format, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import uniq from 'lodash/uniq';
import ReactApexChart from 'react-apexcharts';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getPayWallBanner } from 'src/services/informative';
import {
  getAutocompleteFidcsFundsOptions,
  getFundsHistory,
} from 'src/services/operation';
import { FidcFundHistory, FidcFundOption } from 'src/types/operation';

import { AutocompleteOption, FundAutocomplete } from './FundAutocomplete';
import { notify } from '../Calculator/utils/handleNotify';

type ChartSerie = {
  name: string;
  data: number[];
  color: string;
};

type FlattenHistory = {
  cnpj: string;
  color: string;
  data: number;
  competenceDate: string;
};

interface SelectedFund extends FidcFundOption {
  highlightColor: string;
  selectedClassAndSerie: string;
  histories: FidcFundHistory[];
}

type Fund = {
  name: string;
  cnpj: string;
};

type LocationState = {
  fundA: Fund;
  fundB: Fund;
};

type PeriodOption = number | null;

const PERIOD_OPTIONS = [
  {
    label: '12 Meses',
    value: 12,
  },
  {
    label: '24 Meses',
    value: 24,
  },
  {
    label: 'Tudo',
    value: null,
  },
];

const OperationProfitabilityComparison = (): JSX.Element => {
  const theme = useTheme();
  const location = useLocation<LocationState>();
  const { auth } = useAuthContext();

  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.comparar_fundos;

  const [chartSeries, setChartSeries] = useState<ChartSerie[]>([]);
  const [selectedFunds, setSelectedFunds] = useState<SelectedFund[]>([]);
  const [isUpdatingSeries, setIsUpdatingSeries] = useState<boolean>(false);

  const [chartXAxis, setChartXAxis] = useState<string[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodOption>(12);
  const [fundOptions, setFundOptions] = useState<FidcFundOption[]>([]);

  const { data: contentPayWallBanner } = useQuery(
    'paywall',
    () => getPayWallBanner('compareFunds'),
    {
      enabled: auth?.isLoading,
    },
  );

  useEffect(() => {
    setSelectedFunds([
      {
        classAndSeries: [],
        selectedClassAndSerie: '',
        histories: [],
        highlightColor: theme.palette.primary.main,
        ...location.state.fundA,
      },
      {
        classAndSeries: [],
        selectedClassAndSerie: '',
        histories: [],
        highlightColor: theme.palette.secondary.main,
        ...location.state.fundB,
      },
    ]);
  }, [location.state.fundA, location.state.fundB]);

  const getSelectedCNPJs = (funds?: FidcFundOption[]) =>
    (funds ?? selectedFunds).flatMap((fund) => fund.cnpj);

  const selectedCNPJs = selectedFunds.flatMap(({ cnpj }) => cnpj);
  const getFundHistoriesMutation = useMutation(
    ['fund-histories', selectedCNPJs.join(','), selectedPeriod],
    ({ CNPJs, period }: { CNPJs: string[]; period: PeriodOption }) =>
      getFundsHistory({
        CNPJs,
        period,
      }),
    {
      onSuccess: (histories) => {
        setSelectedFunds((currentSelectedFunds) => {
          if (!currentSelectedFunds.length) return [];

          const updatedFunds = currentSelectedFunds.map((fund) => {
            const historiesFounded = histories.filter(
              (history) => history.cnpj_fundo === fund.cnpj,
            );

            if (historiesFounded) {
              return {
                ...fund,
                histories: historiesFounded,
              };
            }

            return fund;
          });

          handleUpdateSeries(updatedFunds);

          return updatedFunds;
        });
      },
      onError: () => notify('Erro ao buscar histórico do fundo A', 'error'),
    },
  );

  const { isLoading: isLoadingFundOptions } = useQuery(
    'funds-options',
    () => getAutocompleteFidcsFundsOptions(),
    {
      onSuccess: (options) => {
        setFundOptions(options);

        setSelectedFunds((currentSelectedFunds) => {
          if (!currentSelectedFunds.length) return [];

          const updatedFunds = currentSelectedFunds.flatMap((fund) => {
            const optionFounded = options.find(
              (option) => option.cnpj === fund.cnpj,
            );

            if (optionFounded) {
              return {
                ...fund,
                classAndSeries: optionFounded.classAndSeries,
                selectedClassAndSerie: optionFounded.classAndSeries?.[0] ?? '',
              };
            }

            return fund;
          });

          getFundHistoriesMutation.mutate({
            CNPJs: getSelectedCNPJs(updatedFunds),
            period: selectedPeriod,
          });

          return updatedFunds;
        });
      },
      onError: () => notify('Erro ao buscar fundos FIDCs', 'error'),
    },
  );

  const handleUpdateSeries = (funds: SelectedFund[]) => {
    const selectedClassAndSeries = funds.map(
      (fund) => fund.selectedClassAndSerie,
    );

    setIsUpdatingSeries(true);

    if (selectedClassAndSeries.every((value) => value !== '')) {
      const histories: FlattenHistory[] = funds.flatMap((fund) =>
        fund.histories
          .filter(
            (history) => history.classe_serie === fund.selectedClassAndSerie,
          )
          .map((fundHistory) => ({
            cnpj: fund.cnpj,
            color: fund.highlightColor,
            data: fundHistory.rentabilidade_mensal,
            competenceDate: fundHistory.data_competencia,
          })),
      );

      const validHistories = histories.filter(
        (history) => history.competenceDate && !isValid(history.competenceDate),
      );

      const dateObjects = validHistories.map(
        (item) => new Date(item.competenceDate),
      );

      const minDate = new Date(
        Math.min(...dateObjects.map((d) => d.getTime())),
      );
      const maxDate = new Date(
        Math.max(...dateObjects.map((d) => d.getTime())),
      );

      const allDates: string[] = [];
      const currentDate = new Date(minDate);

      while (currentDate <= maxDate) {
        allDates.push(format(currentDate, 'MM/yyyy', { locale: ptBR }));
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      const series: Record<string, ChartSerie> = {};

      allDates.forEach((dateStr) => {
        funds.forEach((fund) => {
          const existingEntry = validHistories.find(
            (item) =>
              item.cnpj === fund.cnpj &&
              format(new Date(item.competenceDate), 'MM/yyyy', {
                locale: ptBR,
              }) === dateStr,
          );
          if (!series[fund.cnpj]) {
            series[fund.cnpj] = {
              name: fund.name,
              color: fund.highlightColor,
              data: new Array(allDates.length).fill(0),
            };
          }

          const index = allDates.indexOf(dateStr);
          if (existingEntry) {
            series[fund.cnpj].data[index] = existingEntry.data;
          }
        });
      });

      const flattenedDates = uniq(allDates);

      setChartSeries(Object.values(series));
      setChartXAxis(flattenedDates);
      setIsUpdatingSeries(false);
    }
  };

  const handleSelectFund = async (
    selectedOption: AutocompleteOption | null,
    fundIndex: number,
  ) => {
    if (!selectedOption) return;

    const cnpj = selectedOption.value;

    const fundToAdd = fundOptions.find((fund) => fund.cnpj === cnpj);

    if (!fundToAdd) return;

    setSelectedFunds((currentSelectedFunds) => {
      const currentSelectedFundsCopy = [...currentSelectedFunds];

      currentSelectedFundsCopy[fundIndex] = {
        classAndSeries: fundToAdd.classAndSeries,
        cnpj: fundToAdd.cnpj,
        name: fundToAdd.name,
        selectedClassAndSerie: fundToAdd.classAndSeries[0],
        highlightColor: currentSelectedFundsCopy[fundIndex].highlightColor,
        histories: [],
      };

      getFundHistoriesMutation.mutate({
        CNPJs: getSelectedCNPJs(currentSelectedFundsCopy),
        period: selectedPeriod,
      });

      return currentSelectedFundsCopy;
    });
  };

  const handleSelectClass = async (
    classAndSerie: string,
    fundIndex: number,
  ) => {
    if (!classAndSerie) return;

    setSelectedFunds((currentSelectedFunds) => {
      const currentSelectedFundsCopy = [...currentSelectedFunds];

      currentSelectedFundsCopy[fundIndex] = {
        ...currentSelectedFundsCopy[fundIndex],
        selectedClassAndSerie: classAndSerie,
      };

      handleUpdateSeries(currentSelectedFundsCopy);

      return currentSelectedFundsCopy;
    });
  };

  const handleClickPeriod = async (period: PeriodOption) => {
    setSelectedPeriod(period);
    getFundHistoriesMutation.mutate({ CNPJs: getSelectedCNPJs(), period });
  };

  return (
    <Paper
      square
      elevation={0}
      sx={{
        padding: theme.spacing(3, 4),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(3, 2),
        },
        mx: 'auto',
      }}>
      {hasPermission ? (
        <Stack>
          <Stack gap={1} marginBottom={2}>
            <Typography variant="h4">Histórico de rentabilidade</Typography>
            <Divider />
          </Stack>
          <Stack flexDirection="column" gap={2}>
            {selectedFunds.map(
              (
                {
                  cnpj,
                  highlightColor,
                  classAndSeries,
                  name,
                  selectedClassAndSerie,
                },
                selectedFundIndex,
              ) => (
                <Stack
                  key={cnpj}
                  alignItems="center"
                  flexDirection={['column', 'row']}
                  gap={2}>
                  <FundAutocomplete
                    label={`Fundo ${selectedFundIndex}`}
                    color={highlightColor}
                    fundOptions={fundOptions ?? []}
                    isLoading={isLoadingFundOptions}
                    value={{
                      label: name,
                      value: cnpj,
                    }}
                    onSelectFund={(value) =>
                      handleSelectFund(value, selectedFundIndex)
                    }
                  />
                  {!isLoadingFundOptions ? (
                    <Select
                      label="Classe"
                      placeholder="Classe"
                      name={`fundClass_${selectedFundIndex}`}
                      value={selectedClassAndSerie}
                      onChange={(event) =>
                        handleSelectClass(
                          event.target.value as string,
                          selectedFundIndex,
                        )
                      }>
                      {classAndSeries.map((classAndSerie) => (
                        <MenuItem key={classAndSerie} value={classAndSerie}>
                          {classAndSerie
                            .replace(/\?nica/g, 'Única')
                            .replace(/\?nico/g, 'Único')
                            .replace(/S\?rie/g, 'Série')}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <></>
                  )}
                </Stack>
              ),
            )}
          </Stack>
          <Stack>
            <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
              {!isLoadingFundOptions &&
                PERIOD_OPTIONS.map((option) => (
                  <Typography
                    key={option.value}
                    sx={{
                      cursor: 'pointer',
                      textDecoration:
                        option.value === selectedPeriod ? 'underline' : 'none',
                    }}
                    variant="caption"
                    onClick={() =>
                      handleClickPeriod(option.value as PeriodOption)
                    }>
                    {option.label}
                  </Typography>
                ))}
            </Stack>
            {!isUpdatingSeries && (
              <div
                style={{
                  filter: getFundHistoriesMutation.isLoading
                    ? 'blur(5px)'
                    : 'none',
                  pointerEvents: getFundHistoriesMutation.isLoading
                    ? 'none'
                    : 'all',
                }}>
                <ReactApexChart
                  series={chartSeries}
                  options={{
                    chart: {
                      height: 500,
                      type: 'line',
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: 'straight',
                    },
                    title: {
                      text: '',
                      align: 'left',
                    },
                    grid: {
                      row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5,
                      },
                    },
                    xaxis: {
                      categories: chartXAxis,
                    },
                  }}
                />
              </div>
            )}
          </Stack>
        </Stack>
      ) : (
        <CardDemonstration
          title={contentPayWallBanner?.profitability?.title}
          subTitle={contentPayWallBanner?.profitability?.subTitle}
          labelButton={contentPayWallBanner?.profitability?.labelButton}
          url={contentPayWallBanner?.profitability?.url}
        />
      )}
    </Paper>
  );
};

export default OperationProfitabilityComparison;
