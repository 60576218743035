import {
  Autocomplete,
  Box,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';
import { FidcFundOption } from 'src/types/operation';

export type AutocompleteOption = {
  label: string;
  value: string;
};

interface FundAutocompleteProps {
  label: string;
  isLoading: boolean;
  fundOptions: FidcFundOption[];
  color?: string;
  value?: AutocompleteOption;
  onSelectFund: (option: AutocompleteOption | null) => void;
}

export const FundAutocomplete = ({
  label,
  color,
  fundOptions,
  isLoading,
  onSelectFund,
  value,
}: FundAutocompleteProps): JSX.Element => {
  const options = fundOptions.map(({ cnpj, name }) => ({
    label: name,
    value: cnpj,
  }));

  return (
    <Stack flexDirection="row" alignItems="center" gap={2}>
      <Box height={40} width={40} borderRadius="50%" bgcolor={color} />
      <Autocomplete
        sx={{ width: 250, maxWidth: '100%' }}
        options={options}
        multiple={false}
        loading={isLoading}
        isOptionEqualToValue={(option: AutocompleteOption, value) =>
          option.value === value.value
        }
        disableClearable
        value={value}
        onChange={(event, option) => onSelectFund(option)}
        getOptionLabel={(option) => option.label}
        noOptionsText="Digite para buscar..."
        loadingText="Carregando..."
        renderInput={(params) => (
          <TextField
            key={label}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};
